import {
    Alert, Backdrop,
    Badge,
    Box, Button,
    Collapse, FormControl, FormControlLabel, FormLabel, Grid,
    IconButton, InputLabel, MenuItem,
    Modal, Pagination, Paper, Select, SelectChangeEvent, Snackbar,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip, Typography
} from "@mui/material";
import {ChangeEvent, createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {OffenseType, ReplayData, Report, ReportAction} from "../util/internalTypes";
import {useApi} from "../contexts/ApiContext";
import {
    Add, Announcement,
    Article,
    Check,
    CheckCircle, CopyAll,
    Delete,
    Download,
    FileDownload,
    FileOpen, FilePresent,
    Gavel,
    Link, Refresh
} from "@mui/icons-material";
import {DISCORD_GUILD_ID} from "../constants";
import styles from './reports.module.css'
import ReplayDataModal from "./replayDataModal";
import moment from "moment-timezone";
import {DatePicker} from "@mui/x-date-pickers";
import {useParams} from "react-router-dom";

interface ActionEditorData {
    open: boolean,
    data: ReportAction
}

interface LocalContextData {
    offenseTypes: OffenseType[]
    reports: Report[],
    replayData: ReplayData | undefined
    setReplayData: (data: ReplayData | undefined) => void
    actionEditor: ActionEditorData
    setActionEditor: Dispatch<SetStateAction<ActionEditorData>>,
    reportActions: ReportAction[],
    copyToCB: (data: string) => void,
    reload: () => void
}
const DEFAULTS = {
    actionEditor: {
        open: false,
        data: {
            id: -1,
            by: '',
            sanctionedPlayer: '',
            sanctionType: 'warning',
            expirationDate: null,
            comments: '',
            offenseLabel: '',
            internalRemarks: '',
            reportId: -1
        }
    }
}
const LocalContext = createContext<LocalContextData>({
    offenseTypes: [],
    reports: [],
    replayData: undefined,
    setReplayData: (data: ReplayData | undefined) => {},
    actionEditor: DEFAULTS.actionEditor,
    setActionEditor: (data: ActionEditorData | ((d: ActionEditorData)=>ActionEditorData)) => {},
    reportActions: [],
    copyToCB: (data: string) => {},
    reload: () => { window.location.reload() }
})

const useLocal = () => useContext(LocalContext)

interface ReportRowProps {reportData: Report}
interface ActionRowProps {actionData: ReportAction}

function ReportActionEditor() {
    const {query} = useApi()
    const {actionEditor, setActionEditor, reports, offenseTypes, reportActions, copyToCB, reload} = useLocal()

    const changeVal = (key: string) => (e: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(!e.target) return
        setActionEditor((d)=>({...d, data: {...d.data, [key]: e.target.value}}))
    }

    //@ts-ignore
    const changeValSimple = (key: string) => (val) => {
        setActionEditor((d)=>({...d, data: {...d.data, [key]: val}}))
    }

    const send = () => {
        if(!actionEditor.data.sanctionedPlayer || !actionEditor.data.offenseLabel || !actionEditor.data.sanctionType
            || (actionEditor.data.sanctionType !== 'warning' && !actionEditor.data.expirationDate)) return
        else {
            query.putReportAction({...actionEditor.data, id: undefined}).then(async ()=> { await setActionEditor(DEFAULTS.actionEditor); reload() })
        }
    }

    const renderPlayersOptions = () => {
        const report = reports.find(x => x.id === actionEditor.data.reportId)
        if(!report) return
        const reported = report.players.map(x => x.playerBT)
        const others = report.replayData?.players ? Object.values(report.replayData.players).filter(x=>x.battleTag !== 'Computer' && !reported.includes(x.battleTag)).map(x=>x.battleTag) : []
        return [...reported, ...others].map((x)=>
            <MenuItem value={x}>{x}</MenuItem>
        )
    }


    return actionEditor.data.reportId ?
        <Modal className={styles.reportActionEditorModal} onClose={() => setActionEditor(DEFAULTS.actionEditor)}
               open={actionEditor.open}>
            <Paper className={styles.paper}>
                <Typography variant={'h6'} component={'div'}>New Action</Typography>
                <Grid spacing={2} container direction={'row'}>
                    <Grid item xs={6} className={styles.formContainer}>
                        <div>
                            Report id: {actionEditor.data.reportId}
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <FormControl style={{width: 'calc(100% - 48px)'}}>
                                <InputLabel>Player</InputLabel>
                                <Select label={'Player'} fullWidth value={actionEditor.data.sanctionedPlayer}
                                        onChange={changeVal('sanctionedPlayer')}>
                                    {
                                        renderPlayersOptions()
                                    }
                                </Select>
                            </FormControl>
                            <Tooltip title={'Copy BattleTag'}><IconButton onClick={()=>actionEditor.data.sanctionedPlayer && copyToCB(actionEditor.data.sanctionedPlayer)} style={{margin: '0 4px'}}><CopyAll/></IconButton></Tooltip>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel>Sanction</InputLabel>
                                <Select label={'Sanction'} fullWidth value={actionEditor.data.sanctionType}
                                        onChange={changeVal('sanctionType')}>
                                    <MenuItem value={'warning'}>Warning</MenuItem>
                                    <MenuItem value={'mute'}>Mute</MenuItem>
                                    <MenuItem value={'ban'}>Ban</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {actionEditor.data.sanctionType !== 'warning' && <div>
                            {/*@ts-ignore*/}
                            Sanction expiration: <DatePicker
                            onChange={changeValSimple('expirationDate')}
                            value={actionEditor.data.expirationDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}/>
                        </div>}
                        <div>
                            <TextField value={actionEditor.data.comments}
                                       onChange={changeVal('comments')}
                                       multiline rows={3} fullWidth placeholder={'Comments'} label={'Comments'}/>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel>Offense</InputLabel>
                                <Select label={'Offense'} value={actionEditor.data.offenseLabel}
                                        onChange={changeVal('offenseLabel')}>
                                    {
                                        Object.values(offenseTypes).map(x => <MenuItem
                                            value={x.label}>{x.label}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        <div>
                            <TextField value={actionEditor.data.internalRemarks}
                                       onChange={changeVal('internalRemarks')}
                                       multiline rows={3} fullWidth placeholder={'Internal remarks'} label={'Internal remarks'}/>
                        </div>
                        <div>
                            <Button onClick={send} color={'primary'} variant={'contained'}>SEND</Button>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4}>Priors</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Action ID
                                    </TableCell>
                                    <TableCell>
                                        Games
                                    </TableCell>
                                    <TableCell>
                                        Offense
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {actionEditor.data.sanctionedPlayer && <TableBody>
                                {reportActions.filter(x=>x.sanctionedPlayer === actionEditor.data.sanctionedPlayer && (!actionEditor.data.offenseLabel || x.offenseLabel === actionEditor.data.offenseLabel)).map(x=><TableRow>
                                    <TableCell>{x.id} ({moment(x.createdAt).format('DD/MM/YYYY')})</TableCell>
                                    {/*@ts-ignore*/}
                                    <TableCell>{x.report.players.find(y=>y.playerBT === x.sanctionedPlayer)?.playerGames}</TableCell>
                                    <TableCell>{x.offenseLabel}</TableCell>
                                    <TableCell>{x.sanctionType} {x.sanctionType !== 'warning' && `${Math.ceil(moment(x.expirationDate).diff(moment(x.createdAt), 'd', true))} days`}</TableCell>
                                </TableRow>)}
                            </TableBody>}
                        </Table>
                    </Grid>
                </Grid>

            </Paper>
        </Modal> : <></>;
}

function ReportActionRow(props: ActionRowProps) {
    const {query} = useApi()
    const {reload} = useLocal()
    const {gamemode} = useParams()

    return <>
        <TableRow>
            <TableCell>{props.actionData.id}</TableCell>
            <TableCell>{props.actionData.sanctionedPlayer}</TableCell>
            <TableCell>{props.actionData.sanctionType} {props.actionData.expirationDate}</TableCell>
            <TableCell>{props.actionData.comments}</TableCell>
            <TableCell>{props.actionData.by}</TableCell>
            <TableCell>Offense: {props.actionData.offenseLabel}<br/>{props.actionData.internalRemarks}</TableCell>
            <TableCell style={{textAlign: 'right'}}><IconButton onClick={()=>query.deleteReportAction(props.actionData.id || 0, gamemode ? parseInt(gamemode) : 1).then(()=>reload())}><Delete/></IconButton></TableCell>
        </TableRow>
    </>
}

function ReportRow(props: ReportRowProps) {
    const {setReplayData, setActionEditor, reload} = useLocal()
    const {query} = useApi()
    const [expand, setExpand] = useState<boolean>(false)

    return <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset!important' } }} style={{backgroundColor: props.reportData.isClosed ? 'rgb(230,230, 230)': 'transparent'}}>
            <TableCell>{props.reportData.id}</TableCell>
            <TableCell>{moment(props.reportData.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
            <TableCell>
                <a target={'_blank'} href={`https://w3champions.com/player/${encodeURIComponent(props.reportData.source?.battleTag || '')}`}>
                    {props.reportData.source?.battleTag}
                </a> aka {props.reportData.sourceDiscordAka ? props.reportData.sourceDiscordAka : props.reportData.sourceDiscordUsername}
            </TableCell>
            <TableCell>{props.reportData.players.map((y,i)=><>
                <a target='_blank' href={`https://w3champions.com/player/${encodeURIComponent(y.playerBT)}`}>
                    {y.playerBT}
                </a> ({y.playerGames} games){i<props.reportData.players.length - 1 && <br/>}
            </>)}</TableCell>
            <TableCell>{props.reportData.reason}</TableCell>

            <TableCell>
                <Tooltip title={'Go to Discord'}>
                    <IconButton onClick={()=>window.open(`discord://discord.com/channels/${DISCORD_GUILD_ID}/${props.reportData.threadId}`)}><Link/></IconButton>
                </Tooltip>
                <Tooltip title={'Download replay'}>
                    <IconButton /*onClick={()=>{
                        fetch(x.file.url,{
                            headers: new Headers({
                                'Origin': window.location.origin
                            }),
                            mode: 'cors'
                        }).then(res=>{
                            console.log(res)
                            return res.blob()
                        }).then(r=>{
                            const downloader: HTMLAnchorElement | null = document.querySelector('#downloader')
                            if (!downloader) return
                            downloader.href = window.URL.createObjectURL(r)
                            downloader.download = `Report#${x.id}_replay.w3g`
                            downloader.click()
                        })
                    }}*/ href={props.reportData.file.url} target={'_blank'}><FileDownload/></IconButton>
                </Tooltip>
                <Tooltip title={'View Replay Data'}><IconButton onClick={()=>setReplayData(props.reportData.replayData)}><Article/></IconButton></Tooltip>
                <Tooltip title={'Actions'}><IconButton onClick={()=>setExpand((e)=>!e)}><Gavel/></IconButton></Tooltip>
                {!props.reportData.isClosed && <Tooltip title={'Close'}><IconButton onClick={()=>query.closeReport(props.reportData.id).then(()=>reload())}><CheckCircle/></IconButton></Tooltip>}
                {props.reportData.isClosed && <Tooltip title={'Reopen'}><IconButton onClick={()=>query.reopenReport(props.reportData.id).then(()=>reload())}><Refresh/></IconButton></Tooltip>}
                {!!props.reportData.attachments.length && <Tooltip title={`${props.reportData.attachments.length} evidence files`}>
                    <IconButton><Badge badgeContent={props.reportData.attachments.length} color={'secondary'}><FilePresent/></Badge></IconButton>
                </Tooltip>}
                {!!props.reportData.appeals.length && <Tooltip title={`${props.reportData.appeals.length} appeals`}>
                    <IconButton><Badge badgeContent={props.reportData.appeals.length} color={'secondary'}><Announcement/></Badge></IconButton>
                </Tooltip>}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Collapse in={expand} timeout={'auto'} unmountOnExit>
                    <Box style={{margin: '0.5rem 1rem', marginLeft: '4rem'}}>
                        <Table>
                            <TableHead>
                                <TableCell>ID</TableCell>
                                <TableCell>Player</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Comments</TableCell>
                                <TableCell>Moderator</TableCell>
                                <TableCell>Offense Type & Remarks</TableCell>
                                <TableCell style={{textAlign: 'right'}}><IconButton onClick={()=>setActionEditor((d: ActionEditorData)=>({
                                    open: true, data: {...d.data, reportId: props.reportData.id}
                                }))}><Add/></IconButton></TableCell>
                            </TableHead>
                            <TableBody>
                                {props.reportData.actions.map(x=><ReportActionRow actionData={x}/>)}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

function ReportsTableLayout() {
    const {reports} = useLocal()

    useEffect(()=>{
    }, [])

    return <>
        <Table className={styles.tableRoot}>
        <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Reported Players</TableCell>
            <TableCell>Reason</TableCell>

            <TableCell/>
        </TableHead>
        <TableBody>
            {reports.sort((a, b) => b.id - a.id).map(x=><ReportRow reportData={x}/>)}
        </TableBody>
    </Table>
    </>
}

export default function ReportsTable() {

    const {query, session} = useApi()

    const {page, gamemode} = useParams()

    const [reports, setReports] = useState<Report[]>([])
    const [pagination, setPagination] = useState<{
        totalPages: number,
        totalRecords: number,
        currentPage: number,
        perPage: number
    }>({
        totalPages:  parseInt(page || '1'),
        totalRecords: 0,
        currentPage: parseInt(page || '1'),
        perPage: 50
    })
    const [loadingState, setLoadingState] = useState<boolean>(false)
    const [reportActions, setReportActions] = useState<ReportAction[]>([])
    const [offenseTypes, setOffenseTypes] = useState<OffenseType[]>([])
    const [replayData, setReplayData] = useState<ReplayData | undefined>(undefined)
    const [copySnackbarOpen, setCopySnackbarOpen] = useState<boolean>(false)

    const [actionEditor, setActionEditor] = useState<ActionEditorData>({
        open: false,
        data: {
            id: -1,
            by: '',
            sanctionedPlayer: '',
            sanctionType: 'warning',
            expirationDate: null,
            comments: '',
            offenseLabel: '',
            internalRemarks: '',
            reportId: -1
        }
    })

    useEffect(()=>{
        const gmId = gamemode ? parseInt(gamemode) : 1;
        query.getReports(parseInt(page || '1'), 50, gmId).then((r) => {
            setReports(r.data)
            setPagination((p) => ({...p, ...r.pagination}))
        })
        query.getOffenseTypes().then(setOffenseTypes)
        query.getReportActions(gmId).then(setReportActions)
    },[])

    const copyToCB = (data: string) => {
        if(window.navigator && window.navigator.clipboard) {
            window.navigator.clipboard.writeText(data).then(r => setCopySnackbarOpen(true))
        }
    }

    const reload = async (page: number | null = null) => {
        const gmId = gamemode ? parseInt(gamemode) : 1;
        window.history.replaceState("", "", `/${gamemode || 1}/${page || 1}`)
        if(page===null) page = pagination.currentPage
        setLoadingState(true)
        const [reports,actions] = await Promise.all([query.getReports(page, 50, gmId), query.getReportActions(gmId)])
        setReports(reports.data)
        // @ts-ignore
        setPagination((p) => ({...p, ...reports.pagination, currentPage: page}))
        setReportActions(actions)
        setLoadingState(false)
    }


    // const MemoizedReportsTableLayout = memo(ReportsTableLayout, [reports, offenseTypes])

    return <LocalContext.Provider value={{
        offenseTypes,
        reports,
        replayData,
        setReplayData,
        actionEditor,
        setActionEditor,
        reportActions,
        copyToCB,
        reload
    }}>
        <Pagination sx={{display: 'flex', justifyContent: 'center'}} onChange={(_, page)=>reload(page)} page={pagination.currentPage} count={pagination.totalPages}/>
        <ReportsTableLayout/>
        <Pagination sx={{display: 'flex', justifyContent: 'center'}} onChange={(_, page)=>reload(page)} count={pagination.totalPages} page={pagination.currentPage}/>
        <ReportActionEditor/>
        <ReplayDataModal data={replayData} onClose={()=>setReplayData(undefined)}/>
        <Snackbar anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }} open={copySnackbarOpen} autoHideDuration={3000} onClose={()=>setCopySnackbarOpen(false)}>
            <Alert onClose={()=>setCopySnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                Copied to clipboard!
            </Alert>
        </Snackbar>
        <Backdrop open={loadingState} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant={'h3'} style={{color: 'white'}}>LOADING...</Typography>
        </Backdrop>
    </LocalContext.Provider>
}
