import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import {Route, BrowserRouter, Routes} from 'react-router-dom'
import {Button} from "@mui/material";
import {ApiProvider, useApi} from "./contexts/ApiContext";
import ReportsTable from "./layouts/reports";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

function SessionInfo() {
    const {session} = useApi()
    return <div>{session ? `BattleTag: ${session.battleTag}, Gateway: ${session.gateway}` : `No session`}</div>
}

function App() {
  return (
    <div className="App">
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <ApiProvider>
            <SessionInfo/>

            <BrowserRouter>
                <Routes>
                    <Route path={':gamemode'} element={<ReportsTable/>}>
                        <Route path={':page'} element={<ReportsTable/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ApiProvider>
        </LocalizationProvider>
    </div>
  );
}

export default App;
